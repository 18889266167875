import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Inventories are catalogs or lists of entities like hosts, services, locations, or people.
Inventories provide a secure mapping between details that are available from the credential used to request a certificate
and additional metadata that needs to be bound in the issued certificate.
You can use Inventories along with other Smallstep Platform components to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Customize Certificates`}</strong>{` - An inventory can map the hostname of a VM to the name of a workload running on that VM, or map an email address to a POSIX username.
In both cases, the name in the authentication credential used to request a certificate does not match the name that should be in the issued certificate.`}</li>
      <li parentName="ul"><strong parentName="li">{`Authorize Certificate Requests`}</strong>{` - Inventory metadata can also be used to authorize a certificate request. For example, certificate issuance via the OIDC provisioner can be restricted to a particular subdomain based on group membership information maintained in a user inventory.`}</li>
    </ul>
    <h2>{`Learn More`}</h2>
    <p>{`Inventories are an upcoming feature of the commercial `}<a parentName="p" {...{
        "href": "https://smallstep.com/certificate-manager/"
      }}>{`Smallstep Certificate Manager`}</a>{` offering.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      